import React, { useContext } from "react";
import styles from "./ServiceOrder.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
/* Custom Hook */

/* Contexts */
import { LoadingContext } from "../../../store/LoadingProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* Custom Button */
import Button from "../../../UI/Button/Button";

/* MUI */
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import CircularProgress from "@mui/material/CircularProgress";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { IoCalendar } from "react-icons/io5";
import { Category, Call, Store, CheckedSquare } from "../../../assets/Icons";
import {
	useAcceptServiceByIdMutation,
	useGetWebsiteOrderDataQuery,
	useRejectServiceByIdMutation,
} from "../../../RTK/apiSlices/websiteOrdersApi";
import { useGetServicesQuery } from "../../../RTK/apiSlices/servicesApi";

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#3AE374",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#3AE374",
	},
}));
const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const ServiceOrder = () => {
	const { id } = useParams();

	const { data: fetchedData, isLoading: loading } = useGetWebsiteOrderDataQuery(
		{ serviceOrderId: id }
	);

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const navigate = useNavigate();
	const [rejectServiceById] = useRejectServiceByIdMutation();
	const rejectServiceByIdHandel = async (formData) => {
		try {
			const res = await rejectServiceById({
				id: fetchedData?.websiteorders?.id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/orders");
			} else {
				setLoadingTitle(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const rejectService = () => {
		setLoadingTitle("جاري رفض الخدمة");
		let formData = new FormData();
		rejectServiceByIdHandel(formData);
	};

	const [acceptServiceById] = useAcceptServiceByIdMutation();
	const acceptServiceByIdHandel = async (formData) => {
		try {
			const res = await acceptServiceById({
				id: fetchedData?.websiteorders?.id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/orders");
			} else {
				setLoadingTitle(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAcceptService = () => {
		setLoadingTitle("جاري قبول الخدمة");
		let formData = new FormData();
		acceptServiceByIdHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | تفاصيل طلب خدمة</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/orders");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30  ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
					backgroundColor: "rgba(235, 235, 235, 1)",
				}}>
				<div className='flex flex-col justify-between h-full '>
					<div
						className='flex items-center justify-between p-4 pt-24 md:p-4 md:pl-16 md:pr-16'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<div className='w-full'>
							<h2 className='font-semibold md:text-2xl text-[20px] mb-3'>
								تفاصيل الطلب
							</h2>
							<div className='flex'>
								<div className={`flex items-center gap-2 `}>
									<div
										onClick={() => {
											navigate("/orders");
										}}
										className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
										<GoArrowRight
											style={{ color: "#02466A", fontSize: "1.2rem" }}
										/>
									</div>

									<h2 className='font-semibold md:text-[18px] text-[14px] md:ml-4 ml-2'>
										{" "}
										الطلبات{" "}
									</h2>
								</div>

								<h2
									onClick={() => {
										navigate("/orders");
									}}
									className='font-semibold md:text-[18px] text-[14px] md:ml-4 ml-2 cursor-pointer'>
									{" "}
									/ جدول الطلبات{" "}
								</h2>

								<h3
									className='font-medium md:text-[18px] text-[14px]'
									style={{ color: "#67747B" }}>
									/ تفاصيل طلب خدمة
								</h3>
							</div>
						</div>
						<div className='flex-col hidden md:flex'>
							<h2
								className={"font-medium md:text-[18px] text-[16px]"}
								style={{ color: "#4D4F5C" }}>
								رقم الخدمة
							</h2>
							<div
								className='flex items-center justify-center h-16 mt-1 rounded-lg shadow-lg w-44'
								style={{ backgroundColor: "#B6BE34" }}>
								<h2 className='text-2xl font-medium text-slate-50'>
									{loading ? (
										<CircularProgress color='inherit' size='16px' />
									) : (
										fetchedData?.websiteorders?.order_number
									)}
								</h2>
							</div>
						</div>
					</div>
					<div
						className={`flex-1 overflow-y-scroll md:pl-16 p-4  ${styles.content}`}
						style={{ backgroundColor: "rgb(235, 235, 235)" }}>
						<Box
							className='p-2 md:pt-4 md:pr-16'
							style={{ backgroundColor: "#F6F6F6" }}>
							<TabContext value={"1"}>
								<Box
									sx={{
										"& path, & g": {
											fill: "#1DBBBE",
										},
									}}>
									<div
										className={
											"md:mt-8 md:gap-12 gap-0 md:p-6 p-2 flex md:flex-row flex-col justify-between"
										}
										style={{
											width: "752px",
											backgroundColor: "#fff",
											maxWidth: "100%",
										}}>
										{loading ? (
											<div className='flex flex-row items-center justify-center w-full gap-4'>
												<CircularProgress size='24px' />{" "}
												<span>جاري التحميل</span>
											</div>
										) : (
											<>
												<div className='flex flex-col items-center mb-8 md:hidden'>
													<h2
														className={"font-medium md:text-[18px] text-[16px]"}
														style={{ color: "#4D4F5C" }}>
														رقم الخدمة
													</h2>
													<div
														className='flex items-center justify-center h-16 mt-1 rounded-lg shadow-lg w-44'
														style={{ backgroundColor: "#B6BE34" }}>
														<h2 className='text-2xl font-medium text-slate-50'>
															{loading ? (
																<CircularProgress color='inherit' size='16px' />
															) : (
																fetchedData?.websiteorders?.order_number
															)}
														</h2>
													</div>
												</div>
												<div className='flex-1'>
													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex gap-2'
															style={{ width: "136px" }}>
															<Store />
															{fetchedData?.websiteorders?.store?.store_name ? (
																<h2>اسم المتجر</h2>
															) : (
																<h2>اسم المستخدم</h2>
															)}
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium'
																style={{ color: "#0077FF" }}>
																{fetchedData?.websiteorders?.store
																	?.store_name ??
																	fetchedData?.websiteorders?.name}
															</h2>
														</div>
													</div>

													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex gap-2'
															style={{ width: "136px" }}>
															<Category />
															<h2>الأنشطة</h2>
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium'
																style={{ color: "#0077FF" }}>
																{fetchedData?.websiteorders?.store?.activity[0]
																	?.name ?? "لا يوجد أنشطة"}
															</h2>
														</div>
													</div>

													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex gap-2'
															style={{ width: "136px" }}>
															<Call />
															<h2>الهاتف</h2>
														</div>

														<BootstrapTooltip
															className={"p-0"}
															TransitionProps={{ timeout: 300 }}
															TransitionComponent={Zoom}
															title='ارسال رسالة عبر الواتساب'
															placement='top-start'>
															<div
																className={
																	"flex-1 flex items-center justify-center rounded-lg"
																}
																style={{
																	backgroundColor: "#EFF9FF",
																	height: "70px",
																	width: "180px",
																}}>
																{fetchedData?.websiteorders?.store
																	?.phonenumber ? (
																	<a
																		className='font-medium'
																		style={{
																			color: "#0077FF",
																			direction: "ltr",
																		}}
																		href={`https://wa.me/${
																			fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																				"+966"
																			) ||
																			fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																				"00966"
																			)
																				? fetchedData?.websiteorders?.store
																						?.phonenumber
																				: `+966${fetchedData?.websiteorders?.store?.phonenumber}`
																		}`}
																		target='_blank'
																		rel='noreferrer'>
																		{fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																			"+966"
																		) ||
																		fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																			"00966"
																		)
																			? fetchedData?.websiteorders?.store
																					?.phonenumber
																			: `+966${fetchedData?.websiteorders?.store?.phonenumber}`}
																	</a>
																) : (
																	<a
																		className='font-medium'
																		style={{
																			color: "#0077FF",
																			direction: "ltr",
																		}}
																		href={`https://wa.me/${
																			fetchedData?.websiteorders?.phone_number?.startsWith(
																				"+966"
																			) ||
																			fetchedData?.websiteorders?.phone_number?.startsWith(
																				"00966"
																			)
																				? fetchedData?.websiteorders
																						?.phone_number
																				: `+966${fetchedData?.websiteorders?.phone_number}`
																		}`}
																		target='_blank'
																		rel='noreferrer'>
																		{fetchedData?.websiteorders?.phone_number?.startsWith(
																			"+966"
																		) ||
																		fetchedData?.websiteorders?.phone_number?.startsWith(
																			"00966"
																		)
																			? fetchedData?.websiteorders?.phone_number
																			: `+966${fetchedData?.websiteorders?.phone_number}`}
																	</a>
																)}
															</div>
														</BootstrapTooltip>
													</div>
												</div>
												<div className='flex-1'>
													<div className='flex items-center gap-4 mb-10 lg-mb-5'>
														<div
															className='flex items-center gap-2'
															style={{ width: "136px" }}>
															<IoCalendar></IoCalendar>
															<h2>تاريخ الطلب</h2>
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium'
																style={{ color: "#0077FF" }}>
																{moment(
																	fetchedData?.websiteorders?.created_at
																).format("DD/MM/YYYY")}
															</h2>
														</div>
													</div>
													<div
														className={`${styles.boxSerivce} flex gap-4 mb-5 `}>
														<div className='flex gap-2'>
															<Call></Call>
														</div>
														<div>
															<h2 className='whitespace-nowrap text-[#000]'>
																نوع الخدمة المطلوبة
															</h2>
															<FormGroup
																sx={{
																	"& .MuiFormControlLabel-root": {
																		gap: "0.1rem",
																	},
																	"& .MuiCheckbox-root": {
																		p: 0,
																	},
																	"& .MuiButtonBase-root svg path": {
																		fill: "#000",
																	},
																	"& .MuiButtonBase-root.Mui-checked svg path":
																		{
																			fill: "#000",
																		},

																	"& .MuiTypography-root": {
																		fontSize: "16px",
																		color: "#000",
																	},
																	"& .MuiFormControlLabel-root:has(.Mui-checked) .MuiTypography-root":
																		{
																			fontSize: "16px",
																			color: "#000",
																		},
																}}>
																{fetchedData?.websiteorders?.services?.map(
																	(item, index) => (
																		<FormControlLabel
																			key={index}
																			sx={{
																				cursor: " default",
																				py: 1,
																				mr: 0,
																				pr: 0,
																				"& .MuiTypography-root": {
																					fontSize: "18px",
																					fontWeight: "500",
																				},
																			}}
																			control={
																				<Checkbox
																					readOnly
																					sx={{
																						cursor: "default",
																					}}
																					checked={fetchedData?.websiteorders?.services
																						?.map((service) => service?.id)
																						.includes(item?.id)}
																					checkedIcon={<CheckedSquare />}
																				/>
																			}
																			label={item?.name}
																		/>
																	)
																)}
															</FormGroup>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
								</Box>
							</TabContext>
						</Box>
					</div>
					<div
						className='flex justify-center gap-4 p-8'
						style={{
							height: "110px",
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						{permissions?.includes("admin.websiteorder.acceptService") && (
							<Button
								className={"h-14 w-44"}
								style={{ backgroundColor: `#3AE374` }}
								type={"normal"}
								disabled={fetchedData?.websiteorders?.status === "منتهية"}
								onClick={handleAcceptService}>
								قبول الخدمة
							</Button>
						)}
						{permissions?.includes("admin.websiteorder.rejectService") && (
							<Button
								className={"h-14 w-44"}
								disabled={fetchedData?.websiteorders?.status === "غير منتهية"}
								style={{ borderColor: `#FF3838` }}
								textStyle={{ color: "#FF3838" }}
								type={"outline"}
								onClick={rejectService}>
								رفض الخدمة
							</Button>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ServiceOrder;
