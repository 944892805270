import React, { useState, useEffect, useContext } from "react";
import styles from "./TabsComp.module.css";
/* Third party */
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

/* Contexts */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { IoIosArrowDown } from "react-icons/io";
import { BsSnapchat, BsYoutube } from "react-icons/bs";
import {
	BsWhatsapp,
	BsFacebook,
	BsInstagram,
	BsTwitter,
	PasswordClose,
	PasswordOpen,
} from "../../../../assets/Icons";
import {
	useGetMarketerDataQuery,
	useUpdateMarketerMutation,
} from "../../../../RTK/apiSlices/marketersApi";
import {
	useAdminCitiesDataQuery,
	useAdminCountriesDataQuery,
} from "../../../../RTK/apiSlices/selectorApi";

const activate = [
	{ id: 1, name: "نشط", name_en: "active" },
	{ id: 2, name: " غير نشط", name_en: "not_active" },
];

const TabsComp = () => {
	const { id } = useParams();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

	const { data: fetchedData, isLoading: loading } = useGetMarketerDataQuery({
		marketerId: id,
	});

	useEffect(() => {
		if (fetchedData?.status === 200) {
			setMainInfo({
				...mainInfo,
				name: fetchedData?.marketers?.name || "",
				user_name: fetchedData?.marketers?.user_name,
				email: fetchedData?.marketers?.email,

				phonenumber: fetchedData?.marketers?.phonenumber,

				snapchat: fetchedData?.marketers?.snapchat,
				facebook: fetchedData?.marketers?.facebook,
				twiter: fetchedData?.marketers?.twiter,

				whatsapp: fetchedData?.marketers?.whatsapp,

				youtube: fetchedData?.marketers?.youtube,
				instegram: fetchedData?.marketers?.instegram,
				city_id: fetchedData?.marketers?.city?.id,
				country_id: fetchedData?.marketers?.country?.id,
				status:
					fetchedData?.marketers?.status === "نشط" ? "active" : "not_active",
			});

			setPreviewImage(fetchedData?.marketers?.image);
		}
	}, [fetchedData]);

	const [mainInfo, setMainInfo] = useState({
		// personal info
		name: "",
		email: "",
		password: "",
		password_confirm: "",
		user_name: "",
		phonenumber: "",

		// social media links
		snapchat: "",
		facebook: "",
		twiter: "",
		whatsapp: "",
		youtube: "",
		instegram: "",

		// city and country
		city_id: "",
		country_id: "",

		// status
		status: "",
	});
	const [images, setImages] = useState([]);
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [previewImage, setPreviewImage] = useState("");
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};
	const navigate = useNavigate();
	const [value, setValue] = React.useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// to set onchange function to all inputs
	const handleMainInfo = (event) => {
		const { name, value } = event.target;

		setMainInfo((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	// to get selectors from api
	const { data: countryList } = useAdminCountriesDataQuery();
	const { data: citiesList } = useAdminCitiesDataQuery();
	/** ---------------------------------------------------------------------------------------------- */

	const [DataError, setDataError] = useState({
		name: "",
		email: "",
		password: "",
		phonenumber: "",
		password_confirm: "",
		user_name: "",
		periodtype: "",
		city_id: "",
		country_id: "",
		image: "",
		snapchat: "",
		facebook: "",
		twiter: "",
		whatsapp: "",
		youtube: "",
		instegram: "",
		status: "",
		checkbox_field: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			email: "",
			password: "",
			phonenumber: "",
			password_confirm: "",
			user_name: "",
			periodtype: "",
			city_id: "",
			country_id: "",
			image: "",
			snapchat: "",
			facebook: "",
			twiter: "",
			whatsapp: "",
			youtube: "",
			instegram: "",
			status: "",
			checkbox_field: "",
		});
	};

	const [updateMarketer] = useUpdateMarketerMutation();
	const updateMarketerHandel = async (formData) => {
		try {
			const res = await updateMarketer({
				marketerId: id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/marketers");
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					email: res?.data?.message?.en?.email?.[0],
					password: res?.data?.message?.en?.password?.[0],
					phonenumber: res?.data?.message?.en?.phonenumber?.[0],
					password_confirm: res?.data?.message?.en?.password_confirm?.[0],
					user_name: res?.data?.message?.en?.user_name?.[0],
					periodtype: res?.data?.message?.en?.periodtype?.[0],
					city_id: res?.data?.message?.en?.city_id?.[0],
					country_id: res?.data?.message?.en?.country_id?.[0],
					image: res?.data?.message?.en?.image?.[0],
					snapchat: res?.data?.message?.en?.snapchat?.[0],
					facebook: res?.data?.message?.en?.facebook?.[0],
					twiter: res?.data?.message?.en?.twiter?.[0],
					whatsapp: res?.data?.message?.en?.whatsapp?.[0],
					youtube: res?.data?.message?.en?.youtube?.[0],
					instegram: res?.data?.message?.en?.instegram?.[0],
					status: res?.data?.message?.en?.status?.[0],
					checkbox_field: res?.data?.message?.en?.checkbox_field?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.email?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.password?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.phonenumber?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.password_confirm?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.user_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.periodtype?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.city_id?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.country_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.image?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.snapchat?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.facebook?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.twiter?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.whatsapp?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.youtube?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.instegram?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.status?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.checkbox_field?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateMarkter = () => {
		setLoadingTitle("جاري تعديل المندوب");
		resetData();
		let formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("name", mainInfo?.name);
		formData.append("email", mainInfo?.email);
		formData.append(
			"phonenumber",
			mainInfo?.phonenumber?.startsWith("+966") ||
				mainInfo?.phonenumber?.startsWith("00966")
				? mainInfo?.phonenumber
				: `+966${mainInfo?.phonenumber}`
		);
		//formData.append('password', mainInfo?.password);
		//formData.append('password_confirm', mainInfo?.password_confirm);
		formData.append("user_name", mainInfo?.user_name);
		//formData.append('periodtype', mainInfo?.periodtype);

		/** ------------------------------------------- */
		formData.append("city_id", mainInfo?.city_id);
		formData.append("country_id", mainInfo?.country_id);

		/** ------------------------------------------- */
		if (images.length !== 0) {
			formData.append("image", images[0]?.file || null);
		}
		/** ------------------------------------------- */
		formData.append("snapchat", mainInfo?.snapchat);
		formData.append("facebook", mainInfo?.facebook);
		formData.append("twiter", mainInfo?.twiter);
		formData.append(
			"whatsapp",
			mainInfo?.whatsapp?.startsWith("+966") ||
				mainInfo?.whatsapp?.startsWith("00966")
				? mainInfo?.whatsapp
				: `+966${mainInfo?.whatsapp}`
		);
		formData.append("youtube", mainInfo?.youtube);
		formData.append("instegram", mainInfo?.instegram);

		formData.append("status", mainInfo?.status || "");

		updateMarketerHandel(formData);
	};

	return (
		<Box className='mt-6 md:mt-16'>
			<TabContext value={value}>
				<Box sx={{}}>
					<TabList
						sx={{
							"& .MuiTabs-scroller": {
								overflow: "auto !important",
							},
							"& .MuiTabs-flexContainer": {
								gap: "1rem",
							},
							"& .MuiButtonBase-root": {
								minWidth: "200px",
								backgroundColor: "#C0E9FF",
								py: "1.25rem",
								borderRadius: "8px",
								color: "#011723",
								transition: "0.1s",
								fontSize: "1.25rem",
								fontWeight: "500",
							},
							"& .MuiButtonBase-root.Mui-selected": {
								backgroundColor: "#02466A",
								color: "#FFFFFF",
							},
							"& .MuiTabs-indicator": { display: "none" },
						}}
						onChange={handleChange}
						variant='fullWidth'>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='البيانات الأساسية'
							value='1'
						/>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='الدولة/المدينة'
							value='2'
						/>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='التواصل الاجتماعى'
							value='3'
						/>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='تفعيل / تعطيل'
							value='4'
						/>
					</TabList>
				</Box>
				{loading ? (
					<Box className='xl:h-[40.5rem] xl:mt-[3.75rem] mt-6 xl:pl-[7.5rem] pl-0'>
						<CircularLoading />
					</Box>
				) : (
					<Box
						className={`${
							DataError?.name !== "" ? "md:h-[53rem]" : "md:h-[43.5rem]"
						} xl:mt-[3.75rem] mt-6 xl:pl-[7.5rem] pl-0`}>
						<TabPanel value='1' className='md:pr-[18px] p-0'>
							<div className='flex flex-col gap-5'>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#67747B", fontSize: "18px" }}>
										اسم المندوب<span className='text-red-500'>*</span>
									</label>
									<input
										className='bg-white outline-none w-full px-3 py-[14px] rounded-md'
										style={{ border: "1px solid #E9E9E9" }}
										type='text'
										placeholder='عبد العزيز محمد'
										name='name'
										value={mainInfo?.name}
										onChange={handleMainInfo}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.name}
									</span>
								</div>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#67747B", fontSize: "18px" }}>
										اسم المستخدم<span className='text-red-500'>*</span>
									</label>
									<input
										className='bg-white outline-none w-full px-3 py-[14px] rounded-md'
										style={{ border: "1px solid #E9E9E9" }}
										type='text'
										placeholder='Abed32'
										name='user_name'
										value={mainInfo?.user_name}
										onChange={handleMainInfo}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.user_name}
									</span>
								</div>
								<div className='flex md:flex-row flex-col gap-[18px]'>
									<div className='w-full flex flex-col gap-[10px]'>
										<label style={{ color: "#67747B", fontSize: "18px" }}>
											كلمة المرور<span className='text-red-500'>*</span>
										</label>
										<div
											className='flex flex-row items-center justify-between bg-white rounded-md px-3 py-[14px]'
											style={{ border: "1px solid #E9E9E9" }}>
											<input
												className='w-full bg-transparent rounded-md outline-none'
												type={showPassword ? "text" : "password"}
												placeholder='•••••••'
												name='password'
												value={mainInfo?.password}
												onChange={handleMainInfo}
											/>
											{showPassword ? (
												<PasswordOpen
													className={styles.password}
													onClick={() => setShowPassword(!showPassword)}
												/>
											) : (
												<PasswordClose
													className={styles.password}
													onClick={() => setShowPassword(!showPassword)}
												/>
											)}
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.password}
										</span>
									</div>
									<div className='w-full flex flex-col gap-[10px]'>
										<label style={{ color: "#67747B", fontSize: "18px" }}>
											تأكيد كلمة المرور<span className='text-red-500'>*</span>
										</label>
										<div
											className='flex flex-row items-center justify-between bg-white rounded-md px-3 py-[14px]'
											style={{ border: "1px solid #E9E9E9" }}>
											<input
												className='w-full bg-transparent rounded-md outline-none'
												type={showPasswordConfirm ? "text" : "password"}
												placeholder='•••••••'
												name='password_confirm'
												value={mainInfo?.password_confirm}
												onChange={handleMainInfo}
											/>
											{showPasswordConfirm ? (
												<PasswordOpen
													className={styles.password}
													onClick={() =>
														setShowPasswordConfirm(!showPasswordConfirm)
													}
												/>
											) : (
												<PasswordClose
													className={styles.password}
													onClick={() =>
														setShowPasswordConfirm(!showPasswordConfirm)
													}
												/>
											)}
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.password_confirm}
										</span>
									</div>
								</div>
								<div className='flex md:flex-row flex-col gap-[18px]'>
									<div className='w-full flex flex-col gap-[10px]'>
										<label style={{ color: "#67747B", fontSize: "18px" }}>
											البريد الالكترونى<span className='text-red-500'>*</span>
										</label>
										<input
											className='bg-white outline-none w-full px-3 py-[14px] rounded-md'
											style={{ border: "1px solid #E9E9E9" }}
											type='email'
											placeholder='Abed@gmail.com'
											name='email'
											value={mainInfo?.email}
											onChange={handleMainInfo}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.email}
										</span>
									</div>
									<div className='w-full flex flex-col gap-[10px]'>
										<label style={{ color: "#67747B", fontSize: "18px" }}>
											رقم التواصل<span className='text-red-500'>*</span>
										</label>
										<div
											className='flex flex-row items-center gap-[1px] bg-white w-full px-3 py-[14px] rounded-md'
											style={{ border: "1px solid #E9E9E9", direction: "ltr" }}>
											<span>+966</span>
											<input
												className='w-full outline-none'
												style={{
													backgroundColor: "transparent",
													direction: "ltr",
												}}
												type='tel'
												placeholder={500000000}
												name='phonenumber'
												value={mainInfo?.phonenumber}
												onChange={handleMainInfo}
												maxLength={9}
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.phonenumber}
										</span>
									</div>
								</div>
								<div className={`mb-5 flex gap-3 items-end `}>
									<div
										className='flex items-center justify-center rounded-md'
										style={{
											backgroundColor: "#D3D3D3",
											height: "160px",
											width: "140px",
										}}>
										<img
											className=' img-responsive'
											src={images[0] ? images[0]?.data_url : previewImage}
											alt=' '
											loading='lazy'
										/>
									</div>
									<div className='flex flex-col w-full'>
										<div className='w-full'>
											<ImageUploading
												value={images}
												onChange={onChange}
												maxNumber={1}
												dataURLKey='data_url'
												acceptType={["jpg", "png", "jpeg"]}
												className='w-full'>
												{({ imageList, onImageUpload, dragProps }) => (
													// write your building UI
													<div>
														<div
															className='relative overflow-hidden upload__image-wrapper'
															style={{
																width: "100%",

																border: images[0]
																	? "1px solid #E9E9E9"
																	: "1px solid #E9E9E9",
																borderRadius: "4px",
															}}
															onClick={() => {
																onImageUpload();
															}}
															{...dragProps}>
															<div
																className='flex w-full cursor-pointer image-item'
																style={{ height: "56px" }}>
																{/* <button
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button> */}
																{!images[0] && (
																	<div className='flex flex-1'>
																		<div className='flex-1'></div>
																		<div
																			className='flex flex-col items-center justify-center px-10 rounded-lg'
																			style={{
																				width: "180px",
																				height: "56px",
																				backgroundColor: "#237EAE",
																				color: "#ffffff",
																			}}>
																			استعراض
																		</div>
																	</div>
																)}
																{images[0] && (
																	<div className='flex flex-1'>
																		<div className='flex flex-col items-center justify-center flex-1'>
																			<h6
																				style={{
																					fontSize: "18px",
																					color: "#000000",
																					fontWeight: "500",
																				}}>
																				{images[0].file.name}
																			</h6>
																		</div>
																		<div
																			className='flex flex-col items-center justify-center px-10 rounded-lg'
																			style={{
																				width: "180px",
																				height: "56px",
																				backgroundColor: "#237EAE",
																				color: "#ffffff",
																			}}>
																			استعراض
																		</div>
																	</div>
																)}
															</div>
														</div>
													</div>
												)}
											</ImageUploading>
											<span className='text-gray-400 font-light text-[14px]'>
												(الحد الأقصى للصورة 1MB)
											</span>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.image}
										</span>
									</div>
								</div>
							</div>
						</TabPanel>
						<TabPanel value='2' className='md:pr-[18px] p-0'>
							<div className='flex flex-col gap-5'>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										الدولة<span className='text-red-500'>*</span>
									</label>
									<Select
										displayEmpty
										style={{ fontSize: "18px", border: "1px solid #E9E9E9" }}
										name='country_id'
										value={mainInfo?.country_id}
										onChange={handleMainInfo}
										IconComponent={() => {
											return <IoIosArrowDown size={"1rem"} />;
										}}
										inputProps={{ "aria-label": "Without label" }}
										renderValue={(selected) => {
											if (mainInfo?.country_id === "") {
												return <h2>اختر الدولة</h2>;
											}
											const result = countryList?.countries?.filter(
												(item) => item?.id === parseInt(selected)
											);

											return result[0]?.name;
										}}
										className='bg-white outline-none w-full  py-[14px] rounded-md'
										sx={{
											height: "3.5rem",
											pl: "1rem",
											"& .MuiOutlinedInput-notchedOutline": {
												border: "1px solid #E9E9E9",
											},
											"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										}}>
										{countryList?.countries?.map((item) => {
											return (
												<MenuItem
													key={item.id}
													className='w-full'
													sx={{
														width: "100%",
														backgroundColor: "#fff",
														height: "3rem",
													}}
													value={item?.id}>
													{item?.name}
												</MenuItem>
											);
										})}
									</Select>
									<span className='text-red-500 text-[16px]'>
										{DataError?.city_id}
									</span>
								</div>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										المدينة<span className='text-red-500'>*</span>
									</label>
									<Select
										displayEmpty
										style={{ fontSize: "18px", border: "1px solid #E9E9E9" }}
										name='city_id'
										value={mainInfo?.city_id}
										onChange={handleMainInfo}
										IconComponent={() => {
											return <IoIosArrowDown size={"1rem"} />;
										}}
										inputProps={{ "aria-label": "Without label" }}
										renderValue={(selected) => {
											if (mainInfo?.city_id === "") {
												return <h2>اختر المدينة</h2>;
											}
											const result = citiesList?.cities?.filter(
												(item) => item?.id === parseInt(selected)
											);
											return result[0]?.name;
										}}
										className='bg-white outline-none w-full  py-[14px] rounded-md'
										sx={{
											height: "3.5rem",
											pl: "1rem",
											"& .MuiOutlinedInput-notchedOutline": {
												border: "1px solid #E9E9E9",
											},
											"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										}}>
										{citiesList?.cities?.map((item) => {
											return (
												<MenuItem
													key={item.id}
													className='w-full'
													sx={{
														width: "100%",
														backgroundColor: "#fff",
														height: "3rem",
													}}
													value={item?.id}>
													{item?.name}
												</MenuItem>
											);
										})}
									</Select>
									<span className='text-red-500 text-[16px]'>
										{DataError?.country_id}
									</span>
								</div>
							</div>
						</TabPanel>
						<TabPanel value='3' className='md:pr-[18px] p-0'>
							<div className='flex flex-col gap-5'>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										رقم واتساب
									</label>
									<div className={` flex-1 relative`}>
										<input
											className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
											type='tel'
											placeholder='512345678'
											style={{
												border: "1px solid #E9E9E9",
												direction: "ltr",
											}}
											name='whatsapp'
											value={mainInfo?.whatsapp}
											onChange={handleMainInfo}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<BsWhatsapp
												className={styles.icons}
												width='20px'
												height='20px'></BsWhatsapp>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.whatsapp}
									</span>
								</div>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										سناب شات
									</label>
									<div className={` flex-1 relative   `}>
										<input
											className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
											type='text'
											style={{
												border: "1px solid #E9E9E9",
												direction: "ltr",
											}}
											name='snapchat'
											value={mainInfo?.snapchat}
											onChange={handleMainInfo}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<BsSnapchat
												className={styles.icons}
												width='20px'
												height='20px'></BsSnapchat>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.snapchat}
									</span>
								</div>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										فيسبوك
									</label>
									<div className={` flex-1 relative   `}>
										<input
											className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
											placeholder=''
											type='text'
											style={{
												border: "1px solid #E9E9E9",
												direction: "ltr",
											}}
											name='facebook'
											value={mainInfo?.facebook}
											onChange={handleMainInfo}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<BsFacebook
												className={styles.icons}
												width='20px'
												height='20px'></BsFacebook>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.facebook}
									</span>
								</div>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										تويتر
									</label>
									<div className={` flex-1 relative   `}>
										<input
											className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
											type='text'
											style={{
												border: "1px solid #E9E9E9",
												direction: "ltr",
											}}
											name='twiter'
											value={mainInfo?.twiter}
											onChange={handleMainInfo}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<BsTwitter
												className={styles.icons}
												width='20px'
												height='20px'></BsTwitter>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.twiter}
									</span>
								</div>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										انستجرام
									</label>
									<div className={` flex-1 relative`}>
										<input
											className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
											type='text'
											style={{
												border: "1px solid #E9E9E9",
												direction: "ltr",
											}}
											name='instegram'
											value={mainInfo?.instegram}
											onChange={handleMainInfo}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<BsInstagram
												className={styles.icons}
												width='20px'
												height='20px'></BsInstagram>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.instegram}
									</span>
								</div>
								<div className='flex flex-col gap-[10px]'>
									<label style={{ color: "#000000", fontSize: "18px" }}>
										يوتيوب
									</label>
									<div className={` flex-1 relative`}>
										<input
											className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
											type='text'
											style={{
												border: "1px solid #E9E9E9",
												direction: "ltr",
											}}
											name='youtube'
											value={mainInfo?.youtube}
											onChange={handleMainInfo}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<BsYoutube
												className={styles.icons}
												width='20px'
												height='20px'></BsYoutube>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.youtube}
									</span>
								</div>
							</div>
						</TabPanel>
						<TabPanel value='4' className='md:pr-[18px] p-0'>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									الحالة<span className='text-red-500'>*</span>
								</label>
								<Select
									name='status'
									value={mainInfo?.status}
									onChange={handleMainInfo}
									style={{ fontSize: "18px", border: "1px solid #E9E9E9" }}
									IconComponent={() => {
										return <IoIosArrowDown size={"1rem"} />;
									}}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}
									renderValue={(selected) => {
										const result = activate?.filter(
											(item) => item?.name_en === selected
										);
										return result[0]?.name;
									}}
									className='bg-white outline-none w-full  py-[14px] rounded-md'
									sx={{
										height: "3.5rem",
										pl: "1rem",
										backgroundColor: "#fff",
										width: "100%",
										"& .MuiOutlinedInput-notchedOutline": {
											border: "1px solid #E9E9E9",
										},
										"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
											border: "1px solid #03787A",
										},
									}}>
									{activate?.map((item, idx) => {
										return (
											<MenuItem
												key={idx}
												className=''
												sx={{
													backgroundColor: "#fff",
													height: "3rem",

													"&:hover": {},
													"ul:has(&)": {
														padding: "0",
													},
												}}
												value={item?.name_en}>
												{item?.name}
											</MenuItem>
										);
									})}
								</Select>
								<span className='text-red-500 text-[16px]'>
									{DataError?.status}
								</span>
							</div>
						</TabPanel>
					</Box>
				)}
			</TabContext>
			<Button
				onClick={updateMarkter}
				className={"w-full mt-10 py-3 text-lg"}
				type={"normal"}>
				حفظ
			</Button>
		</Box>
	);
};

export default TabsComp;
