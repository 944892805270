import React, { useState, Fragment, useContext, useEffect } from "react";
import styles from "./EditLesson.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
/* Context */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import AddNewVideo from "./AddNewVideo";

/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { IoMdCloudUpload } from "react-icons/io";
import { Copy } from "../../../../assets/Icons";

// RTK query
import {
	useGetExplainVideoDataQuery,
	useUpdateExplainVideosMutation,
} from "../../../../RTK/apiSlices/coursesApi";
import Context from "../../../../store/context";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 pb-36  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const EditLesson = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const contextStore = useContext(Context);
	const { setSelectedId } = contextStore;

	const handleClosePage = () => {
		navigate("/atlbha_academy");
		setSelectedId(3);
	};

	const { data: fetchedData, isLoading: loading } = useGetExplainVideoDataQuery(
		{ lessonId: id }
	);

	const [data, setData] = useState({
		title: "",
		video: "",
		thumbnail: "",
		link: "",
	});
	const {
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			title: "",
		},
	});

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [images, setImages] = useState([]);
	const [video, setVideo] = useState("");
	const [copy, setCopy] = useState(false);
	const handelCopy = () => {
		navigator.clipboard.writeText(data?.link);
		setCopy(true);
		toast.success("تم نسخ الرابط بنجاح");
		setTimeout(() => {
			setCopy(false);
		}, 5000);
	};

	useEffect(() => {
		if (fetchedData?.status === 200) {
			setData({
				...data,
				title: fetchedData?.$explainvideos?.title,
				video: fetchedData?.$explainvideos?.video,
				thumbnail: fetchedData?.$explainvideos?.thumbnail,
				link: fetchedData?.$explainvideos?.url,
			});
			setVideo(fetchedData?.$explainvideos?.video);
		}
	}, [fetchedData]);

	useEffect(() => {
		reset(data);
	}, [data, reset]);

	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChange = (imageList) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				thumbnail: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, thumbnail: null });
		}
	};

	const [DataError, setDataError] = useState({
		title: "",
		video: "",
		thumbnail: "",
	});

	const resetData = () => {
		setDataError({
			title: "",
			video: "",
			thumbnail: "",
		});
	};

	const [updateExplainVideos] = useUpdateExplainVideosMutation();

	const updateExplainVideosHandeler = async (formData) => {
		try {
			const res = await updateExplainVideos({
				lessonId: id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				handleClosePage();
			} else {
				setLoadingTitle(null);
				setDataError({
					title: res?.data?.message?.en?.title?.[0],
					video: res?.data?.message?.en?.video?.[0],
					thumbnail: res?.data?.message?.en?.thumbnail?.[0],
				});
				toast.error(res?.data?.message?.en?.title?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.video?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.thumbnail?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddLesson = (dataAddLesson) => {
		resetData();
		const formData = new FormData();
		formData.append("title", dataAddLesson?.title);
		if (images.length !== 0) {
			formData.append("thumbnail", images[0]?.file || null);
		}
		if (video !== "") {
			formData.append("video", video || null);
		}
		setLoadingTitle("جاري تعديل الدرس");
		formData.append("_method", "PUT");
		updateExplainVideosHandeler(formData);
	};

	return (
		<Fragment>
			<Helmet>
				<title>لوحة تحكم أطلبها | تعديل درس</title>
			</Helmet>
			<BackDrop onClick={handleClosePage} />
			<div
				className={`fixed top-[70px] left-0 bg-slate-50 z-30 otlobha_new_product ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<form onSubmit={handleSubmit(handleAddLesson)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='flex flex-col md:py-[30px] md:pr-[27px] p-4 gap-[10px]'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2
								style={{ color: "#011723" }}
								className='md:text-[24px] text-[20px] font-bold'>
								تعديل درس
							</h2>
							<p
								style={{ color: "#011723" }}
								className='md:text-[20px] text-[16px] font-normal'>
								تعديل درس لقسم الشروحات في اكاديمية اطلبها
							</p>
						</div>
						{loading ? (
							<div className='flex flex-col items-center justify-center w-full h-full'>
								<CircularLoading />
							</div>
						) : (
							<>
								<div
									style={{ backgroundColor: "#F6F6F6" }}
									className={`flex-1 flex flex-col gap-8 overflow-y-scroll md:py-8 md:pr-[27px] md:pl-[157px] p-4 ${styles.content}`}>
									<div className='flex flex-col items-start md:flex-row md:items-center gap-y-4'>
										<label
											style={{ color: "#011723" }}
											className='md:w-80 w-full md:text-[20px] text-[18px] font-medium whitespace-nowrap'>
											عنوان الدرس<span className='text-red-500'>*</span>
										</label>
										<div className='flex flex-col w-full gap-2'>
											<Controller
												name={"title"}
												control={control}
												rules={{
													required: "حقل العنوان مطلوب",
													pattern: {
														value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
														message: "يجب ان يتكون الاسم من أحرف",
													},
												}}
												render={({ field: { onChange, value } }) => (
													<input
														className='w-full md:h-14 h-[45px] rounded-md px-5 py-4 outline-none'
														style={{
															fontSize: "20px",
															color: "#011723",
															backgroundColor: "#02466A00",
															border: "1px solid #A7A7A7",
														}}
														placeholder='عنوان الفيديو'
														type='text'
														name='title'
														value={value}
														onChange={(e) => {
															onChange(e.target.value);
														}}
													/>
												)}
											/>
											<span className='text-red-500 text-[16px]'>
												{DataError?.title}
												{errors?.title && errors.title.message}
											</span>
										</div>
									</div>
									<div className='flex flex-col items-start md:flex-row md:items-center gap-y-4'>
										<h2
											style={{ color: "#011723", fontSize: "20px" }}
											className='min-w-[14.5rem] font-medium whitespace-nowrap'>
											ارفاق فيديو الدرس<span className='text-red-500'>*</span>
										</h2>
										<div className='flex flex-col w-full gap-2'>
											<div className={styles.add_video}>
												<AddNewVideo
													value={video}
													setValue={setVideo}
													placeholder='قم بنسخ رابط الامبدد الخاص بالفيديو من اليوتيوب'
												/>
											</div>
											<span className='text-red-500 text-[16px]'>
												{DataError?.video}
											</span>
										</div>
									</div>
									<div className='flex flex-col items-start md:flex-row md:items-center gap-y-4'>
										<label
											className='font-medium w-80 whitespace-nowrap'
											style={{ color: "#011723", fontSize: "20px" }}>
											صورة تعريفية <span className='text-red-500'>*</span>
										</label>
										<div className='flex flex-col w-full gap-2'>
											<div className='text-gray-400 font-light text-[16px]'>
												المقاس المقترح : 440 * 440
											</div>
											<ImageUploading
												value={images}
												onChange={onChange}
												maxNumber={2}
												dataURLKey='data_url'
												acceptType={["jpg", "png", "jpeg"]}>
												{({ onImageUpload, dragProps }) => (
													// write your building UI
													<div
														className='relative mx-auto overflow-hidden upload__image-wrapper'
														style={{
															width: "100%",
															padding: "8px",
															border: "1px dashed #02466A",
															borderRadius: "10px",
															strokeDasharray: "'6%2c5'",
														}}
														onClick={() => {
															onImageUpload();
														}}
														{...dragProps}>
														<div className='w-full h-full cursor-pointer image-item'>
															<div className='flex flex-col items-center justify-center w-full h-full'>
																<IoMdCloudUpload size={"2em"}></IoMdCloudUpload>
																<h2
																	style={{
																		color: "#011723",
																		fontSize: "16px",
																	}}>
																	اسحب الصورة هنا
																</h2>
																<div className='flex flex-col items-center gap-1'>
																	<h2 className='mb-0'>
																		(سيتم قبول الصور png & jpg & jpeg)
																	</h2>
																	<span className='text-gray-400 font-light text-[14px]'>
																		(الحد الأقصى للصورة 1MB)
																	</span>
																</div>
															</div>
														</div>
													</div>
												)}
											</ImageUploading>

											<span className='text-red-500 text-[16px]'>
												{DataError?.thumbnail}
											</span>
										</div>
									</div>
									<div className='flex flex-col items-start md:flex-row '>
										<label className='font-medium w-80 whitespace-nowrap'></label>
										{(images[0] || data) && (
											<div className='md:w-[400px] w-[100%] md:h-[400px] h-[100%] '>
												<img
													src={images[0]?.data_url || data?.thumbnail}
													alt='preview-img'
													loading='lazy'
												/>
											</div>
										)}
									</div>
									<div className='flex flex-col items-start md:flex-row md:items-center gap-y-4'>
										<label
											className='font-medium w-80 whitespace-nowrap'
											style={{ color: "#02466A", fontSize: "20px" }}>
											رابط الدورة{" "}
											<span style={{ color: "#02466A", fontSize: "16px" }}>
												(تلقائي)
											</span>
										</label>
										<div
											className='md:h-14 h-[45px] w-full flex flex-row items-center justify-between rounded-md gap-3 p-4'
											style={{
												backgroundColor: "#F4F5F7",
												border: "1px solid #67747B33",
											}}>
											{copy ? (
												<h6 style={{ color: "#02466A", fontSize: "16px" }}>
													Copied
												</h6>
											) : (
												<Copy
													className='cursor-pointer'
													fill='#02466A'
													onClick={() => handelCopy()}
												/>
											)}
											<input
												className='md:h-14 h-[45px] outline-none text-left'
												style={{
													width: "100%",
													backgroundColor: "transparent",
													color: "#02466A",
													whiteSpacepace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
												value={data?.link}
												type='text'
												disabled
											/>
										</div>
									</div>
								</div>
								<div
									className='md:h-[135px] h-[100px] md:p-8 p-4 flex items-center justify-center gap-4 md:bg-[#ebebeb] bg-[#f6f6f6]'
									style={{
										backgroundColor: "rgba(235, 235, 235, 1)",
									}}>
									<Button
										btnType={"submit"}
										className='font-medium md:h-14 h-[45px] w-full md:w-1/3'
										style={{
											backgroundColor: `#02466A`,
										}}
										textStyle={{ color: "#EFF9FF", fontSize: "22px" }}
										type={"normal"}>
										حفظ
									</Button>
									<Button
										className='font-medium md:h-14 h-[45px] w-full md:w-1/3'
										style={{
											borderColor: `rgba(2, 70, 106, 1)`,
										}}
										textStyle={{ color: "#02466A", fontSize: "22px" }}
										type={"outline"}
										onClick={handleClosePage}
										btnType={"button"}>
										إلغاء
									</Button>
								</div>
							</>
						)}
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default EditLesson;
