import React, { useEffect, useState, useContext } from "react";

/* Third party */
import { useNavigate } from "react-router-dom";

// components
import MyAccount from "../MyAccount/MyAccount";

// Context
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";

/* MUI */
import { styled } from "@mui/material";
import Badge from "@mui/material/Badge";
import { Avatar } from "@mui/material";

/* Icons */
import { MdKeyboardArrowDown } from "react-icons/md";
import { MyAccountIcon, SignOut } from "../../assets/Icons/index";
import { useProfileDataQuery } from "../../RTK/apiSlices/profileApi";

// css styles
import styles from "./ProfileMenu.module.css";
import { useLogOutMutation } from "../../RTK/apiSlices/logOutApi";

const BackDrop = ({ closeMenu }) => {
	return (
		<div
			onClick={closeMenu}
			className='fixed bottom-0 left-0 z-10 w-full h-full back_drop'></div>
	);
};

// Style dot active on avatar image
const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

const ProfileMenu = () => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [editUser, setEditUser] = useState(false);
	const [showMyProfile, setShowMyProfile] = useState(false);

	// using RolePermissionsStore to get Role Permissions of user
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { setRole, setPermissions } = RolePermissionsStore;

	// RTK Query
	const { data: profileData, isLoading } = useProfileDataQuery();
	localStorage?.setItem("verification_count", profileData?.verification_count);

	// to send permissions into setPermissions context
	useEffect(() => {
		setRole(profileData?.users?.role?.name);
		setPermissions(
			profileData?.users?.role?.permissions?.map(
				(permissions) => permissions?.name
			)
		);
	}, [profileData?.users?.role?.name, profileData?.users?.role?.permissions]);

	// handle logout from admin
	const [logout] = useLogOutMutation();
	const handleLogout = async () => {
		// make request...
		try {
			const response = await logout();

			if (
				response.data?.success === true &&
				response.data?.data?.status === 200
			) {
				localStorage.clear();

				// handle timeout to reload page and navigate user to login page
				window.location.reload();
				setTimeout(() => {
					navigate("/login");
				}, 1000);
			}
		} catch (error) {
			console.error("Error changing logout:", error);
		}
	};
	return (
		<div className='md:min-w-[220px] relative h-full order-last md:order-first'>
			<div
				className={`flex items-center gap-2 cursor-pointer ${styles.user}`}
				style={{ color: "#1DBBBE" }}
				onClick={() => setOpen(!open)}>
				<StyledBadge
					overlap='circular'
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					variant='dot'>
					<Avatar
						alt='profile-icon'
						src={profileData?.users?.image}
						style={{
							minWidth: "35px",
							maxWidth: "35px",
							minHeight: "35px",
							maxHeight: "35px",
							borderRadius: "50%",
							padding: "5px",
							border: "1px solid #dddddd",
							backgroundColor: "#ffffff",
						}}
						loading='lazy'
					/>
				</StyledBadge>
				<MdKeyboardArrowDown className='hidden text-2xl md:flex' />
				<h2 className='hidden md:flex' style={{ color: "#fff" }}>
					{profileData?.users?.name}
				</h2>
			</div>
			<div className={`${open ? "flex" : "hidden"}`}>
				<BackDrop
					closeMenu={() => {
						setOpen(false);
					}}></BackDrop>
				<div className='min-w-[220px] md:bg-[#A4A1FB] bg-[#C0E9FF] flex flex-col gap-4 md:text-[#F7FCFF] text-[#02466A] w-full py-3 md:absolute fixed md:top-14 top-[4.7rem] left-0 z-20'>
					<div className='flex flex-row gap-4 px-6'>
						<MyAccountIcon />
						<span
							className='md:text[16px] text-[18px] md:text-[#F7FCFF] text-[#02466A] whitespace-nowrap cursor-pointer'
							onClick={() => {
								setShowMyProfile(true);
							}}>
							حسابي
						</span>
						{showMyProfile && (
							<MyAccount
								user={profileData?.users}
								loading={isLoading}
								edit={editUser}
								setEditUser={setEditUser}
								cancel={() => {
									setShowMyProfile(false);
								}}
							/>
						)}
					</div>
					<div
						className='flex flex-row gap-4 px-6 cursor-pointer'
						onClick={handleLogout}>
						<SignOut />
						<span className='whitespace-nowrap'>تسجيل الخروج</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ProfileMenu;
